import state from './moduleScheduleCommitmentsState.js'
import mutations from './moduleScheduleCommitmentsMutations.js'
import actions from './moduleScheduleCommitmentsActions.js'
import getters from './moduleScheduleCommitmentsGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

