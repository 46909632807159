<template>
    <div>
        <vs-sidebar click-not-close parent="#schedule-practical" :hidden-background="true" class="full-vs-sidebar email-view-sidebar items-no-padding" v-model="isSidebarActive" position-right>
            <div class="mail-sidebar-content px-0 sm:pb-6 h-full" v-if="schedule.id">
                <div class="flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg">
                    <div class="flex mb-4">
                        <div class="flex items-center">
                            <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="$emit('closeSidebar')" class="cursor-pointer hover:text-primary mr-4" svg-classes="w-6 h-6"></feather-icon>
                            <h4>VOLTAR</h4>
                        </div>
                    </div>
                    <div class="ml-10 mb-4 mt-1">
                        <div class="email__actions--single flex items-baseline">
                            <!-- <vx-tooltip text="Imprimir" position="top" class="ml-4">
                              <feather-icon icon="PrinterIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('markUnread')"></feather-icon>
                            </vx-tooltip> -->
                            <vx-tooltip text="Deletar este agendamento" position="top" class="ml-4">
                              <feather-icon icon="TrashIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('delete')"></feather-icon>
                            </vx-tooltip>
                        </div>
                    </div>
                </div>

                <component :is="scrollbarTag" class="scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6" :settings="settings" :key="$vs.rtl">

                  <div v-if="isSidebarActive">

                    <!-- LATEST MESSAGE -->
                    <div class="vx-row">
                        <div class="vx-col w-full">
                          <email-mail-card :schedule="schedule" />
                        </div>
                    </div>

                    <div class="vx-row" style="margin-top: 2.2rem">
                      <div class="vx-col w-full">
                        <!-- EDITANDO AGENDA -->
                        <vx-card>
                          <div class="vx-row">
                            <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid">
                              <h5 class="mb-1">Editando</h5>
                            </div>
                          </div>

                          <div class="vx-row">
                            <div class="vx-col md:w-full w-full mt-2">
                              <vs-input v-model="localSchedule.title" class="w-full" label="Título"
                                data-vv-scope="schedule" data-vv-as="Título" v-validate.initial="'required'" name="title" />
                              <span class="text-danger text-sm" v-show="errors.has('schedule.title')">{{ errors.first('schedule.title') }}</span>
                            </div>
                          </div>
                          <div class="vx-row">
                            <div class="vx-col md:w-full w-full mt-2">
                              <vs-input v-model="localSchedule.observation" class="w-full" label="Conteúdo" />
                            </div>
                          </div>
                          <div class="vx-row">
                            <div class="vx-col md:w-1/3 w-full mt-2">
                              <label class="vs-input--label">Atribuído para</label>
                              <v-select v-model="localSchedule.employee_id" :reduce="option => option.value" :options="employees" placeholder="Selecione"
                                data-vv-scope="schedule" data-vv-as="Atribuído para" v-validate.initial="'required'" name="employee_id"
                                style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                                <template #no-options="{}">
                                  Desculpe, nenhum resultado encontrado.
                                </template>
                              </v-select>
                              <span class="text-danger text-sm" v-show="errors.has('schedule.employee_id')">{{ errors.first('schedule.employee_id') }}</span>
                            </div>
                            <div class="vx-col md:w-1/4 w-full mt-2">
                              <label class="vs-input--label">Situação</label>
                              <v-select v-model="localSchedule.status" :reduce="option => option.value"
                                :options="[
                                  { value: 'in_progress', label: 'Em progresso'},
                                  { value: 'finished', label: 'Finalizado'},
                                  { value: 'canceled', label: 'Cancelado'}
                                ]"
                                :clearable="false" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                                <template #no-options="{}">
                                  Desculpe, nenhum resultado encontrado.
                                </template>
                              </v-select>
                            </div>

                            <div class="vx-col md:w-2/5 w-full mt-0">
                              <div class="mt-4 flex flex-wrap items-center justify-end" style="margin-right: -10px">
                                <vx-tooltip text="Data do Agendamento" position="top" class="ml-auto mr-2 mt-4">
                                  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer hover:text-primary">
                                    <a href.prevent class="flex cursor-pointer">
                                      <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="p-2 rounded-full" :class="[`text-primary`, 'mb-0']"
                                        :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                                    </a>
                                    <vs-dropdown-menu class="w-60" style="z-index: 200001">
                                      <vs-input type="date" v-model="localSchedule.start_date" class="w-full" label="Data" />
                                    </vs-dropdown-menu>
                                  </vs-dropdown>
                                </vx-tooltip>

                                <vx-tooltip text="Defina o horário e clique no dia desejado." position="top" class="mt-4 flex">
                                  <vc-date-picker v-model="time" mode="time" class="ml-1 flex" is24hr :timezone="timezone" />
                                <span class="mt-2"> &nbsp;à&nbsp; </span>
                                  <vc-date-picker v-model="endTime" mode="time" class="ml-1 flex" is24hr :timezone="timezone" />
                                </vx-tooltip>

                            </div>
                          </div>
                          </div>
                          <vs-divider></vs-divider>

                          <div class="vx-row">
                            <div class="vx-col w-full">
                              <div class="mt-4 flex flex-wrap items-center justify-end">
                                <vs-button class="ml-4" @click="update" :disabled="!validateForm">Alterar</vs-button>
                              </div>
                            </div>
                          </div>

                        </vx-card>
                      </div>
                    </div>

                  </div>
                </component>
            </div>
        </vs-sidebar>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EmailMailCard from './CommitmentsCard.vue'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showThread: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.50
      },
      timezone: '', //America/Sao_Paulo
      time: null,
      endTime: null,
      localSchedule: {}
    }
  },
  watch: {
    async isSidebarActive (value) {
      if (!value) {
        this.$emit('closeSidebar')
        setTimeout(() => {
          this.showThread = false
          this.localSchedule = {}
        }, 500)
      } else {
        const localSchedule = JSON.parse(JSON.stringify(this.schedule))
        localSchedule.start_date = localSchedule.start_date.slice(0, 10)
        localSchedule.duration = '00:50' // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        this.localSchedule = localSchedule
        this.time = new Date(`${this.localSchedule.start_date}T${this.localSchedule.start_time}`)
        this.endTime = new Date(`${this.localSchedule.start_date}T${this.localSchedule.end_time}`)
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    validateForm () {
      return !this.errors.any()
    },
    schedule () {
      return this.$store.state.scheduleCommitments.schedule
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    async update () {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_commitment')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      try {
        this.$vs.loading()

        if      (this.localSchedule.status === 'in_progress') this.localSchedule.bg = 'rgba(255, 159, 67, 0.3)' // #FF9F43
        else if (this.localSchedule.status === 'finished') this.localSchedule.bg = 'rgba(21, 132, 130, 0.3)' // #158482
        else if (this.localSchedule.status === 'canceled') this.localSchedule.bg = 'rgba(234, 84, 85, 0.3)' // #EA5455

        // AJUSTE DE HORÁRIOS
        this.time = new Date(this.time)
        const time = `${this.time.getHours() < 10 ? 0 : ''}${this.time.getHours()}:${this.time.getMinutes() < 10 ? 0 : ''}${this.time.getMinutes()}:00`
        this.endTime = new Date(this.endTime)
        const endTime = `${this.endTime.getHours() < 10 ? 0 : ''}${this.endTime.getHours()}:${this.endTime.getMinutes() < 10 ? 0 : ''}${this.endTime.getMinutes()}:00`

        this.localSchedule.start_time = time
        this.localSchedule.end_time = endTime

        const resp = await this.$store.dispatch('scheduleCommitments/update', this.localSchedule)

        let filter = false
        if (this.schedule.employee_id !== this.localSchedule.employee_id) {
          filter = true
        }

        this.$store.commit('scheduleCommitments/SET_SCHEDULE', resp.data)
        if (filter) {
          this.$emit('filterSchedule') // fiz isso pq não removia o agendamento quando alterava a atribuição ao employee
        } else {
          this.$store.commit('scheduleCommitments/FILL_SELECTED_DAYS')
        }

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        console.error(error)
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    EmailMailCard,
    vSelect
  }
}

</script>

