<template>
    <div id="schedule-practical" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">

        <vs-sidebar class="items-no-padding" parent="#schedule-practical" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isEmailSidebarActive">
          <schedule-sidebar @closeSidebar="toggleEmailSidebar" />
        </vs-sidebar>

        <!-- MENU DE CONTEXTO -->
        <vue-context ref="menu">
          <!-- <li v-if="contextAbsence">
            <a href="#" @click="contextMenuClicked('ABSENCE')" class="flex items-center text-sm hover:text-warning">
              <feather-icon icon="SlashIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Falta sem Justificativa</span>
            </a>
          </li> -->
          <li v-if="contextDelete">
            <a href="#" @click="contextMenuClicked('DELETE')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Excluir Agendamento</span>
            </a>
          </li>
        </vue-context>

        <div :class="{'sidebar-spacer': clickNotClose}" class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">

          <!-- SEARCH BAR AND MENU ICON -->
          <div class="flex border d-theme-dark-bg items-center">
            <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleEmailSidebar(true)"/>
          </div>

          <!-- EMAIL ACTION BAR -->
          <div class="email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
            <div class="vx-row">
              <div class="vx-col md:w-full w-full mt-2">
                <vs-input v-model="title" class="w-full" label="Título" />
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-full w-full mt-2">
                <vs-input v-model="content" class="w-full" label="Conteúdo" />
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full mt-2">
                <label class="vs-input--label">Atribuído para</label>
                <v-select v-model="employee_id" @input="changeEmployee()" :reduce="option => option.value" :options="employees" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/4 w-full mt-2">
                <label class="vs-input--label">Situação</label>
                <v-select v-model="status" @input="changeSituation()" :reduce="option => option.value"
                  :options="[
                    { value: 'in_progress', label: 'Em progresso'},
                    { value: 'finished', label: 'Finalizado'},
                    { value: 'canceled', label: 'Cancelado'}
                  ]"
                  :clearable="false" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>

              <div class="vx-col md:w-2/5 w-full mt-0">
                <div class="mt-4 flex flex-wrap items-center justify-end" style="margin-right: -10px">

                  <vx-tooltip text="Defina o horário e clique no dia desejado." position="top" class="mt-4 flex">
                    <vc-date-picker v-model="time" mode="time" class="ml-1 flex" is24hr :timezone="timezone" />
                   <span class="mt-2"> &nbsp;à&nbsp; </span>
                    <vc-date-picker v-model="endTime" mode="time" class="ml-1 flex" is24hr :timezone="timezone" />
                    <!-- <feather-icon icon="PlusCircleIcon" svgClasses="h-8 w-8" class="mt-3 ml-2 hover:text-primary"></feather-icon> -->
                  </vx-tooltip>

                </div>
              </div>
            </div>
          </div>

          <!-- SCHEDULE -->
          <component :is="scrollbarTag" class="email-content-scroll-area" :settings="settings" ref="mailListPS" :key="$vs.rtl">
            <div class="vs-con-loading__container" id="loading">

              <vs-table v-for="(table, tIndex) in tables" :key="tIndex" :data="selectedDays" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
                <template slot="thead">
                  <vs-th style="font-size: 11px; min-width: 124px" v-for="(hr, i) in handleRange(table)" :key="i" @click.native="addSchedule(selectedDays[hr - 1])">
                    {{ weekDays[selectedDays[hr - 1].weekdayPosition] }} {{ selectedDays[hr - 1].id | dateTime('date') }}
                  </vs-th>
                </template>
                <template slot-scope="{ data }">

                  <vs-tr height="400" style="font-size: 12px; opacity: 1 !important; vertical-align: top;">

                    <vs-td v-for="(hr, itd) in handleRange(table)" :key="itd" @contextmenu.prevent.native.self="contextMenu($event, null, hr - 1)"
                      @click.native="addSchedule(selectedDays[hr - 1])">
                      <feather-icon v-for="(holiday, hd) in selectedDays[hr - 1].holidaySchedule" :key="`${hd}_holiday`" :title="holiday.holidayName" class="mr-1" icon="StarIcon" svgClasses="w-4 h-4" />
                      <div v-for="(schedule, idx) in selectedDays[hr - 1].employeeSchedule" :key="`${idx}_schedule`" class="p-2 text-black flex items-center hover:text-primary cursor-pointer"
                        :style="[{backgroundColor: schedule.bg}, { marginBottom: '5px' }]" @click.stop="viewSchedule(schedule)"
                        @contextmenu.prevent="contextMenu($event, schedule, hr - 1)">

                        <span>{{ schedule.start_time.slice(0, 5) }} - {{ schedule.title }}</span>

                      </div>

                    </vs-td>
                  </vs-tr>

                  <vs-tr style="opacity: 1 !important; border: 10px">
                    <vs-td :colspan="handleRange(table).length + 1" style="text-align:right">
                      <vs-divider></vs-divider>
                    </vs-td>
                  </vs-tr>

                </template>
              </vs-table>
            </div>
            <div v-if="!tables" class="mt-6 flex flex-wrap items-center justify-center">
              <vs-alert class="w-5/6" style="height: -webkit-fill-available;" color="warning" title="Defina o período" :active="!tables">
                Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver
                os agendamentos conforme o filtro superior.
              </vs-alert>
            </div>
          </component>
        </div>

        <!-- EMAIL VIEW SIDEBAR -->
        <schedule-view
            :request         ="request"
            :isSidebarActive ="isSidebarActive"
            @filterSchedule  ="filterSchedule"
            @delete          ="confirmDelete"
            @closeSidebar    ="closeViewSidebar">
        </schedule-view>
    </div>
</template>

<script>

import _                    from 'lodash'
import { VueContext }       from 'vue-context'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
// import moment               from 'moment'

import ScheduleSidebar      from './CommitmentsSidebar.vue'
import ScheduleView         from './CommitmentsView.vue'
import VuePerfectScrollbar  from 'vue-perfect-scrollbar'

import moduleScheduleCommitments from '@/store/schedule-commitments/moduleScheduleCommitments.js'
import moduleEmployee            from '@/store/employee/moduleEmployee.js'

export default {
  data () {
    return {
      request              : false,
      contextDelete        : false,

      // contextAbsence       : false,
      selectedDay          : null,
      selectedHour         : null,

      timezone: '', //America/Sao_Paulo
      time: new Date(),
      endTime: new Date().setHours(new Date().getHours() + 1),

      isSidebarActive      : false,
      showThread           : false,
      clickNotClose        : true,
      isEmailSidebarActive : true,
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      },
      weekDays: [0, 'SEG.', 'TER.', 'QUA.', 'QUI.', 'SEX.', 'SÁB.', 'DOM.'],
      employee_id: null,
      type: 'commitments',
      status: 'in_progress',
      reportFirstPeriod: '',
      reportLastPeriod: '',
      periodError: false,
      title: null,
      content: null
    }
  },
  watch: {
    time (newValue) {
      // ADICIONA UMA HORA AO HORÁRIO FINAL AO ALTERAR O HORÁRIO INICIAL
      this.endTime = new Date(newValue).setHours(new Date(newValue).getHours() + 1)
    },
    selectedDays (value) {
      if (value.length) {
        this.openLoading()
        this.debouncedGetFilterSchedule()
      }
      // AO TROCAR O PERÍODO DE DATAS DO CALENDÁRIO ZERO O PERÍODO MANUAL DO RELATÓRIO
      this.reportFirstPeriod = ''
      this.reportLastPeriod = ''
    },
    isSidebarActive (value) {
      if (!value) this.showThread = false
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    schedule () {
      return this.$store.state.scheduleCommitments.schedule
    },
    selectedDays () {
      return this.$store.state.scheduleCommitments ? this.$store.state.scheduleCommitments.selectedDays : []
    },
    periods () {
      return this.$store.state.scheduleCommitments.periods // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
    },
    hours () {
      const periods = this.periods
      const h = [
        ['06:00 06:50', '06:50 07:40', '07:40 08:30', '08:30 09:20', '09:20 10:10', '10:10 11:00', '11:00 11:50'],
        ['13:00 13:50', '13:50 14:40', '14:40 15:30', '15:30 16:20', '16:20 17:10', '17:10 18:00', '18:00 18:50'],
        ['19:40 20:30', '20:30 21:20', '22:10 23:00', '23:00 23:50']
      ]
      const res = h.filter(function (eachElem, index) {
        return periods.indexOf(index) !== -1
      }).flat()
      return res
    },
    tables () {
      return Math.ceil(this.selectedDays.length / 6) // CONTROLE DE COLUNAS POR TABELA DE DATAS (7 COLUNAS POR TABELA)
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    openLoading () {
      this.$vs.loading({
        container: '#loading',
        scale: 0.8
      })
    },
    closeLoading () {
      this.$vs.loading.close('#loading > .con-vs-loading')
    },
    contextMenu (event, schedule, selectedDayIdx) {
      this.contextDelete = false
      if (schedule) {
        this.contextDelete = true
        this.$store.commit('scheduleCommitments/SET_SCHEDULE', schedule)
        this.$refs.menu.open(event)
      }


      //    GUARDEI COMENTADO PARA USAR EM CASO DE ALTERAÇÃO DE STATUS PELO CONTEXT


      // this.contextAbsence = false
      // MOSTRA A OPÇÃO DE ALTERAR O STATUS PARA AUSÊNCIA
      // if (schedule.status !== 'ABSENCE') this.contextAbsence = true

    },
    contextMenuClicked (action) {
      if (action === 'DELETE') {
        this.confirmDelete()
      } else if (action === 'ADD') {
        this.addSchedule(this.selectedDay, this.selectedHour, true)
      // } else if (action === 'ABSENCE') { // AUSÊNCIA SEM JUSTIFICATIVA PARA AULA PRÁTICA
      //   this.updateStatus('ABSENCE')
      }
    },
    viewSchedule (schedule) {
      this.$store.commit('scheduleCommitments/SET_SCHEDULE', schedule)
      this.scheduleDetails()
    },

    confirmDelete () {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_commitment')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este agendamento?',
        accept: this.deleteSchedule,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteSchedule () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('scheduleCommitments/delete', this.schedule.id)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.closeViewSidebar()
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    /**
     *  NOVO AGENDAMENTO
     */
    async addSchedule (selectedDay) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_commitment')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.title || !this.employee_id) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Defina <br> <strong>TÍTULO, ATRIBUIÇÃO e SITUAÇÃO</strong> <br> para o agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      // AJUSTE DE HORÁRIOS
      this.time = new Date(this.time)
      const time = `${this.time.getHours() < 10 ? 0 : ''}${this.time.getHours()}:${this.time.getMinutes() < 10 ? 0 : ''}${this.time.getMinutes()}:00`
      this.endTime = new Date(this.endTime)
      const endTime = `${this.endTime.getHours() < 10 ? 0 : ''}${this.endTime.getHours()}:${this.endTime.getMinutes() < 10 ? 0 : ''}${this.endTime.getMinutes()}:00`

      try {
        this.$vs.loading()
        const payload = {
          title: this.title,
          type: this.type,
          status: this.status,
          start_date: selectedDay.id,
          start_time: time,
          end_time: endTime,
          observation: this.content,
          employee_id: this.employee_id,
          student_id: null,
          vehicle_id: null,
          category: null,
          doctor_id: null,
          cac_id: null,
          duration: '00:50'
        }
        await this.$store.dispatch('scheduleCommitments/store', payload)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    // async updateStatus (status) {
    //   try {
    //     this.openLoading()
    //     this.$store.commit('scheduleCommitments/SET_SCHEDULE', { status, duration: '00:50' })  // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
    //     await this.$store.dispatch('scheduleCommitments/update', this.schedule)

    //     this.request = true // FORÇO CONSULTA NA API PARA LSTAGEM MOSTRAR A ALTERAÇÃO
    //     this.closeLoading()
    //     this.$vs.notify({
    //       time: 5000,
    //       title: 'SUCESSO',
    //       text: 'Dados Alterados.',
    //       color: 'success',
    //       iconPack: 'feather',
    //       icon: 'icon-check'
    //     })
    //   } catch (error) { console.error(error) }
    // },
    handleRange (row, hoursPerRow = 6) {
      if (row === this.tables && this.selectedDays.length % hoursPerRow > 0) {
        return _.range((row * hoursPerRow) - (hoursPerRow - 1), (row * hoursPerRow) - (hoursPerRow - 1) + (this.selectedDays.length % hoursPerRow))
      }
      return _.range((row * hoursPerRow) - (hoursPerRow - 1), (row * hoursPerRow) + 1)
    },
    changeEmployee () {
      this.filterSchedule()
    },
    changeSituation () {
      // this.filterSchedule()
    },
    async filterSchedule () {
      // CASO NÃO ENVIE FILTRO ESVAZIA A AGENDA
      if (!this.employee_id) {
        this.$store.commit('scheduleCommitments/SET_HOLIDAY_SCHEDULES', [])
        this.$store.commit('scheduleCommitments/SET_EMPLOYEE_SCHEDULES', [])
        this.$store.commit('scheduleCommitments/FILL_SELECTED_DAYS')
        this.closeLoading()
        return
      }
      try {
        this.openLoading()
        const payload = {
          getByDate: true,
          getByPeriod: false,
          type: this.type,
          dates: this.selectedDays.map(day => day.id),
          employee_id: this.employee_id
        }
        await this.$store.dispatch('scheduleCommitments/fetchFilter', payload) // BUSCA OS AGENDAMENTOS NA API CONFORME FILTRO
        this.$store.commit('scheduleCommitments/FILL_SELECTED_DAYS')           // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
        this.closeLoading()
      } catch (error) { console.error(error) }
    },

    // fillSchedule (schedule, hour) {
    //   if (schedule.employeeSchedule.length) {
    //     const format = 'h:mm:ss'
    //     const beforeTime = moment(`${hour.slice(0, 5)}:00`, format)
    //     const afterTime = moment(`${hour.slice(6, 11)}:00`, format)

    //     const employeeSchedule = schedule.employeeSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
    //     if (employeeSchedule.length) {
    //       employeeSchedule.map((e, idx) => {
    //         if (idx === 0 && employeeSchedule.length > 1) e.bg = 'background-color: rgba(255, 159, 67, 0.3); margin: -6px;' // #FF9F43
    //         else if (idx === 1 && employeeSchedule.length > 1)  e.bg = 'background-color: rgba(255, 159, 67, 0.3); margin: 6px -6px -6px -6px;' // #FF9F43
    //         else {
    //           e.bg = 'background-color: rgba(255, 159, 67, 0.3);' // #FF9F43
    //           e.fill = true
    //         }
    //       })
    //       return employeeSchedule
    //     }
    //   }
    //   return []
    // },
    scheduleDetails () {
      this.isSidebarActive = true
    },
    closeViewSidebar () {
      this.isSidebarActive = false
      this.request = false // RETORNO PARA FALSE AO FECHAR A LISTA PARA NÃO FORÇAR REQUEST NA PRÓXIMA CONSULTA CASO SEJA O MESMO ALUNO
    },
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isEmailSidebarActive = this.clickNotClose = false
      } else {
        this.isEmailSidebarActive = this.clickNotClose = true
      }
    },
    toggleEmailSidebar (value = false) {
      if (!value) {
        this.closeViewSidebar()
        if (this.clickNotClose) {
          return
        }
      }
      this.isEmailSidebarActive = value
    }
  },
  components: {
    ScheduleSidebar,
    ScheduleView,
    VuePerfectScrollbar,
    VueContext,
    vSelect
  },
  created () {
    this.debouncedGetFilterSchedule = _.debounce(this.filterSchedule, 1000)

    if (!moduleScheduleCommitments.isRegistered) {
      this.$store.registerModule('scheduleCommitments', moduleScheduleCommitments)
      moduleScheduleCommitments.isRegistered = true
    }

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => {
      //VINCULA O USUÁRIO A AGENDA MOSTRADA NO INÍCIO
      const idx = this.employees.findIndex((e) => e.data.user_id === this.user.id || e.data.email === this.user.email)
      if (idx >= 0) {
        this.employee_id = this.employees[idx].value
        this.changeEmployee()
      }
    }).catch(err => { console.error(err) })

    this.setSidebarWidth()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";

// #schedule-practical th .vs-table-text {
//     cursor: default;
// }
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#schedule-practical td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#schedule-practical td:hover {
  border-bottom: 1px solid #353434;
}
#schedule-practical td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#schedule-practical .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#schedule-practical .vs-table--thead {
  border: 1px solid transparent;
}
.icon {
  font-size: 16px;
}
#schedule-practical .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#schedule-practical .ps__scrollbar-y-rail {
  z-index: 99999;
}

.vc-date {
  display: none !important;
}
.vc-date-time {
  margin-left: 0px !important;
}
.vc-time-picker {
  display: flex;
  align-items: center;
  padding: 0px !important;
}
.vc-time-icon {
  display: none !important;
}
</style>
