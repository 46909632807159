import Vue from 'vue'

export default {
  SET_SCHEDULE (state, payload) {
    state.schedule = Object.assign({}, state.schedule, payload) //precisa ser assim para manter a reatividade
  },
  UPDATE_SCHEDULE (state, payload) {
    const index2 = state.employeeSchedules.findIndex(o => o.id === payload.id)
    if (index2 >= 0) Vue.set(state.employeeSchedules, index2, payload)
    state.selectedDays.map(e => {
      const idx2 = e.employeeSchedule.findIndex(o => o.id === payload.id)
      if (idx2 >= 0) Vue.set(e.employeeSchedule, idx2, payload)
    })
  },

  SET_SELECTED_DAYS (state, payload) {
    state.selectedDays = payload.sort((a, b) => {   //ORDENA AS DATAS
      return new Date(a.date) - new Date(b.date)
    })
  },
  ADD_EMPLOYEE_SCHEDULE (state, payload) {
    state.employeeSchedules.push(payload)
  },
  SET_HOLIDAY_SCHEDULES (state, payload) {
    state.holidaySchedules = payload //precisa ser assim para manter a reatividade
  },

  UPDATE_PERIOD (state, payload) {
    state.periods = payload
  },

  SET_EMPLOYEE_SCHEDULES (state, payload) {
    state.employeeSchedules = payload //precisa ser assim para manter a reatividade
  },

  // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
  FILL_SELECTED_DAYS (state) {
    // console.time('FILL_SELECTED_DAYS')
    state.selectedDays.map(selectedDay => {
      selectedDay.employeeSchedule.splice(0) // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS
      selectedDay.holidaySchedule.splice(0)   // ESVAZIO AS AGENDAS EM TODOS OS DIAS PREENCHIDOS

      state.employeeSchedules = state.employeeSchedules.sort((a, b) => {   //ORDENA AS DATAS  EM CADA DIA
        return new Date(`${a.start_date.slice(0, 10)}T${a.start_time}`) - new Date(`${b.start_date.slice(0, 10)}T${b.start_time}`)
      })

      state.employeeSchedules.map(schedule => {     // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (selectedDay.id === schedule.start_date.slice(0, 10)) {
          if (schedule.status === 'in_progress') schedule.bg = 'rgba(255, 159, 67, 0.3)' // #FF9F43
          else if (schedule.status === 'finished') schedule.bg = 'rgba(21, 132, 130, 0.3)' // #158482
          else if (schedule.status === 'canceled') schedule.bg = 'rgba(234, 84, 85, 0.3)' // #EA5455
          selectedDay.employeeSchedule.push(schedule)
        }
      })
      state.holidaySchedules.map(schedule => {     // PREENCHO OS DIAS COM AS AGENDAS RECEBIDAS NOS DIAS CORRESPONDENTES
        if (selectedDay.id === schedule.date.slice(0, 10)) {
          selectedDay.holidaySchedule.push(schedule)
        }
      })
    })
    // console.timeEnd('FILL_SELECTED_DAYS')
  },

  //EXCLUI DO ARRAY
  DELETE (state, payload) {
    const index2 = state.employeeSchedules.findIndex(o => o.id === payload)
    if (index2 >= 0) state.employeeSchedules.splice(index2, 1) //precisa ser assim para manter a reatividade

    state.selectedDays.map(e => {
      const idx2 = e.employeeSchedule.findIndex(o => o.id === payload)
      if (idx2 >= 0) e.employeeSchedule.splice(idx2, 1) //precisa ser assim para manter a reatividade
    })

    if (state.schedule && state.schedule.id === payload) {
      state.schedule = Object.assign({}, {}) //precisa ser assim para manter a reatividade
    }
  }
}
