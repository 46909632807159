import axios from '@/axios.js'

export default {
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/employee`, payload)
        .then((response) => {
          commit('STORE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/employee/${payload.id}`, payload)
        .then((response) => {
          commit('SET', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateBlockingSchedules ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/office/hour/${payload.id}`, payload)
        .then((response) => {
          commit('SET', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchEmployee ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/employee/${payload}/edit`)
        .then((res) => {
          commit('SET', res.data)
          resolve(res)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchAll ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/employees`, payload)
        .then((response) => {
          commit('ADD', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchGrid ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/employees`, payload)
        .then((response) => {
          commit('SET_EMPLOYEES_GRID', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/employee/${payload}`)
        .then((response) => {
          commit('DELETE', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
