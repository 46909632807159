export default {
  get (state) {
    const employee = JSON.parse(JSON.stringify(state.employee))
    if (employee.id) {
      employee.address = (employee.addresses && employee.addresses.length) ? employee.addresses[0] : {}
      employee.address.country = 1 // Brasil

      const sortPhonesBy = ['mobile', 'phone', 'other']
      //AJUSTANDO OS TELEFONES VAZIOS
      if (!employee.phones.filter(phone => phone.type === 'mobile').length) {
        employee.phones.unshift({ id: null, phone: null, type: 'mobile' })
      }
      if (!employee.phones.filter(phone => phone.type === 'phone').length) {
        employee.phones.push({ id: null, phone: null, type: 'phone' })
      }
      if (!employee.phones.filter(phone => phone.type === 'other').length) {
        employee.phones.push({ id: null, phone: null, type: 'other' })
      }
      //ORDENANDO OS TELEFONES NA SEQUÊNCIA ABAIXO
      employee.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))

      //ajustando roles para vselect
      employee.roles = employee.roles.map(a => a.id)

      //ajustando flag de acesso ao sistema (true ou false)
      employee.sistemAccess = !!employee.user

      employee.date_cnh_employee = employee.date_cnh_employee && employee.date_cnh_employee.slice(0, 10)
      employee.exame_saude_cnh_employee = employee.exame_saude_cnh_employee && employee.exame_saude_cnh_employee.slice(0, 10)
    }

    if (!employee.office_hour) {
      employee.office_hour = {
        work_seg: false,
        work_ter: false,
        work_qua: false,
        work_qui: false,
        work_sex: false,
        work_sab: false,
        work_dom: false,
        lunch_time: false,
        deny_system_access_out_office_hour: false,
        breaks: [{ weekday: null, start_time: null, end_time: null }]
      }
    } else if (!employee.office_hour.breaks) {
      employee.office_hour.breaks = [{ weekday: null, start_time: null, end_time: null }]
    }

    return employee
  },
  forSelect (state) {
    const employees = []
    for (const k in state.employees) {
      employees.push({
        value: state.employees[k].id,
        label: state.employees[k].name,
        data: state.employees[k]
      })
    }
    return employees
  },
  forSelectOnlyInstrutores (state) {
    const retorno = []
    const soInstrutores = []
    
    for (const e in state.employees) {
      for (const i in state.employees[e].roles) {
        if (state.employees[e].roles[i].name === 'practical_instructor' ||
           state.employees[e].roles[i].name === 'practical_theoretical_instructor') {

          soInstrutores.push(state.employees[e])
          break
        }
      }
    }

    for (const k in soInstrutores) {

      retorno.push({
        value: soInstrutores[k].id,
        label: soInstrutores[k].name,
        data: soInstrutores[k]
      })
    }
    return retorno
  }
}
