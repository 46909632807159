export default {
  SET (state, payload) {
    state.employee = Object.assign({}, state.employee, payload) //precisa ser assim para manter a reatividade
  },
  SET_EMPLOYEES_GRID (state, payload) {
    /** NÃO MOSTRAR OWNERS NA LISTA DE FUNCIONÁRIOS */
    // payload.data = payload.data.filter((obj) => {
    //   if (!obj.user || obj.user.owner !== 1) return obj
    // })
    payload.total = payload.data.length
    state.employeesGrid = payload
  },
  ADD (state, payload) {
    state.employees = payload //precisa ser assim para manter a reatividade
  },
  STORE (state, payload) {
    state.employees.push(payload) //precisa ser assim para manter a reatividade
    state.employees.sort((a, b) => { return a.name.localeCompare(b.name) }) // Ordena por nome

    state.employeesGrid.data.push(payload)
    state.employeesGrid.data.sort((a, b) => { return a.name.localeCompare(b.name) }) // Ordena por nome
    state.employeesGrid.total = state.employeesGrid.total + 1
  },
  DELETE (state, payload) {
    const idx1 = state.employees.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.employees.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.employeesGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.employeesGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.employeesGrid.total = state.employeesGrid.total - 1
    }
  }
}
