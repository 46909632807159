var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "border border-solid d-theme-border-grey-light rounded relative overflow-hidden",
      attrs: { id: "schedule-practical" },
    },
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            parent: "#schedule-practical",
            "click-not-close": _vm.clickNotClose,
            "hidden-background": _vm.clickNotClose,
          },
          model: {
            value: _vm.isEmailSidebarActive,
            callback: function ($$v) {
              _vm.isEmailSidebarActive = $$v
            },
            expression: "isEmailSidebarActive",
          },
        },
        [
          _c("schedule-sidebar", {
            on: { closeSidebar: _vm.toggleEmailSidebar },
          }),
        ],
        1
      ),
      _c("vue-context", { ref: "menu" }, [
        _vm.contextDelete
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "flex items-center text-sm hover:text-danger",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.contextMenuClicked("DELETE")
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "MinusCircleIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "ml-2" }, [
                    _vm._v("Excluir Agendamento"),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        {
          staticClass:
            "no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0",
          class: { "sidebar-spacer": _vm.clickNotClose },
        },
        [
          _c(
            "div",
            { staticClass: "flex border d-theme-dark-bg items-center" },
            [
              _c("feather-icon", {
                staticClass:
                  "md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer",
                attrs: { icon: "MenuIcon" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleEmailSidebar(true)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-full w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Título" },
                      model: {
                        value: _vm.title,
                        callback: function ($$v) {
                          _vm.title = $$v
                        },
                        expression: "title",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-full w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Conteúdo" },
                      model: {
                        value: _vm.content,
                        callback: function ($$v) {
                          _vm.content = $$v
                        },
                        expression: "content",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Atribuído para"),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        reduce: (option) => option.value,
                        options: _vm.employees,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.changeEmployee()
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Desculpe, nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.employee_id,
                        callback: function ($$v) {
                          _vm.employee_id = $$v
                        },
                        expression: "employee_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Situação"),
                    ]),
                    _c("v-select", {
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        reduce: (option) => option.value,
                        options: [
                          { value: "in_progress", label: "Em progresso" },
                          { value: "finished", label: "Finalizado" },
                          { value: "canceled", label: "Cancelado" },
                        ],
                        clearable: false,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.changeSituation()
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Desculpe, nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.status,
                        callback: function ($$v) {
                          _vm.status = $$v
                        },
                        expression: "status",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-2/5 w-full mt-0" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 flex flex-wrap items-center justify-end",
                      staticStyle: { "margin-right": "-10px" },
                    },
                    [
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "mt-4 flex",
                          attrs: {
                            text: "Defina o horário e clique no dia desejado.",
                            position: "top",
                          },
                        },
                        [
                          _c("vc-date-picker", {
                            staticClass: "ml-1 flex",
                            attrs: {
                              mode: "time",
                              is24hr: "",
                              timezone: _vm.timezone,
                            },
                            model: {
                              value: _vm.time,
                              callback: function ($$v) {
                                _vm.time = $$v
                              },
                              expression: "time",
                            },
                          }),
                          _c("span", { staticClass: "mt-2" }, [
                            _vm._v("  à  "),
                          ]),
                          _c("vc-date-picker", {
                            staticClass: "ml-1 flex",
                            attrs: {
                              mode: "time",
                              is24hr: "",
                              timezone: _vm.timezone,
                            },
                            model: {
                              value: _vm.endTime,
                              callback: function ($$v) {
                                _vm.endTime = $$v
                              },
                              expression: "endTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            _vm.scrollbarTag,
            {
              key: _vm.$vs.rtl,
              ref: "mailListPS",
              tag: "component",
              staticClass: "email-content-scroll-area",
              attrs: { settings: _vm.settings },
            },
            [
              _c(
                "div",
                {
                  staticClass: "vs-con-loading__container",
                  attrs: { id: "loading" },
                },
                _vm._l(_vm.tables, function (table, tIndex) {
                  return _c(
                    "vs-table",
                    {
                      key: tIndex,
                      staticClass: "bordered",
                      staticStyle: { overflow: "-webkit-paged-y" },
                      attrs: { data: _vm.selectedDays, hoverFlat: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ data }) {
                              return [
                                _c(
                                  "vs-tr",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      opacity: "1 !important",
                                      "vertical-align": "top",
                                    },
                                    attrs: { height: "400" },
                                  },
                                  _vm._l(
                                    _vm.handleRange(table),
                                    function (hr, itd) {
                                      return _c(
                                        "vs-td",
                                        {
                                          key: itd,
                                          nativeOn: {
                                            contextmenu: function ($event) {
                                              $event.preventDefault()
                                              if (
                                                $event.target !==
                                                $event.currentTarget
                                              )
                                                return null
                                              return _vm.contextMenu(
                                                $event,
                                                null,
                                                hr - 1
                                              )
                                            },
                                            click: function ($event) {
                                              return _vm.addSchedule(
                                                _vm.selectedDays[hr - 1]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.selectedDays[hr - 1]
                                              .holidaySchedule,
                                            function (holiday, hd) {
                                              return _c("feather-icon", {
                                                key: `${hd}_holiday`,
                                                staticClass: "mr-1",
                                                attrs: {
                                                  title: holiday.holidayName,
                                                  icon: "StarIcon",
                                                  svgClasses: "w-4 h-4",
                                                },
                                              })
                                            }
                                          ),
                                          _vm._l(
                                            _vm.selectedDays[hr - 1]
                                              .employeeSchedule,
                                            function (schedule, idx) {
                                              return _c(
                                                "div",
                                                {
                                                  key: `${idx}_schedule`,
                                                  staticClass:
                                                    "p-2 text-black flex items-center hover:text-primary cursor-pointer",
                                                  style: [
                                                    {
                                                      backgroundColor:
                                                        schedule.bg,
                                                    },
                                                    { marginBottom: "5px" },
                                                  ],
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.viewSchedule(
                                                        schedule
                                                      )
                                                    },
                                                    contextmenu: function (
                                                      $event
                                                    ) {
                                                      $event.preventDefault()
                                                      return _vm.contextMenu(
                                                        $event,
                                                        schedule,
                                                        hr - 1
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        schedule.start_time.slice(
                                                          0,
                                                          5
                                                        )
                                                      ) +
                                                        " - " +
                                                        _vm._s(schedule.title)
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "vs-tr",
                                  {
                                    staticStyle: {
                                      opacity: "1 !important",
                                      border: "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "vs-td",
                                      {
                                        staticStyle: { "text-align": "right" },
                                        attrs: {
                                          colspan:
                                            _vm.handleRange(table).length + 1,
                                        },
                                      },
                                      [_c("vs-divider")],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        _vm._l(_vm.handleRange(table), function (hr, i) {
                          return _c(
                            "vs-th",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "11px",
                                "min-width": "124px",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.addSchedule(
                                    _vm.selectedDays[hr - 1]
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.weekDays[
                                      _vm.selectedDays[hr - 1].weekdayPosition
                                    ]
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm._f("dateTime")(
                                      _vm.selectedDays[hr - 1].id,
                                      "date"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  )
                }),
                1
              ),
              !_vm.tables
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mt-6 flex flex-wrap items-center justify-center",
                    },
                    [
                      _c(
                        "vs-alert",
                        {
                          staticClass: "w-5/6",
                          staticStyle: { height: "-webkit-fill-available" },
                          attrs: {
                            color: "warning",
                            title: "Defina o período",
                            active: !_vm.tables,
                          },
                        },
                        [
                          _vm._v(
                            "\n            Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver\n            os agendamentos conforme o filtro superior.\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("schedule-view", {
        attrs: { request: _vm.request, isSidebarActive: _vm.isSidebarActive },
        on: {
          filterSchedule: _vm.filterSchedule,
          delete: _vm.confirmDelete,
          closeSidebar: _vm.closeViewSidebar,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }