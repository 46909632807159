import axios from '@/axios.js'

export default {
  store ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/commitmentsSchedule`, payload)
        .then((response) => {
          commit('ADD_EMPLOYEE_SCHEDULE', response.data)
          commit('FILL_SELECTED_DAYS')
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  update ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/schedule/${payload.id}`, payload)
        .then((response) => {
          commit('UPDATE_SCHEDULE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFilter ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/schedule/get`, payload)
        .then((response) => {
          commit('SET_EMPLOYEE_SCHEDULES', response.data.employeeSchedule)
          commit('SET_HOLIDAY_SCHEDULES', response.data.holidaySchedules)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  delete ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/schedule/${id}`)
        .then((response) => {
          commit('DELETE', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
