<template functional>
  <vx-card class="px-4" :class="data.staticClass">
    <!-- MAIL HEAD -->
    <div class="vx-row">
      <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
        <!-- Sender Details -->
        <div class="flex items-center">
          <div>
            <vs-avatar class="sender__avatar--single flex-shrink-0 mr-3 border-2 border-solid border-white" :src="props.schedule.photo && props.schedule.photo.url" size="65px" />
          </div>

          <div class="flex flex-col">
            <h5 class="mb-1">{{ props.schedule.employee.name }}</h5>
            <div class="flex items-center">
              <span class="text-sm">
                Agendado por: {{ props.schedule.user.name }} <br>
                Status: <span :class="[props.schedule.status === 'canceled' ? 'text-danger' : props.schedule.status === 'in_progress' ? 'text-warning' : 'text-primary', 'font-bold']"> {{ props.schedule.status | scheduleStatus }} </span>
              </span>
            </div>
          </div>
        </div>
        <!-- /Sender Details -->

        <div class="flex flex-col justify-end">
          <span class="text-right font-semibold">Data: {{ props.schedule.start_date | dateTime('date') }}</span>
          <div class="flex justify-end mt-2 font-semibold">
            <span class="text-right">Horário: {{ props.schedule.start_time.slice(0, 5) }} até {{ props.schedule.end_time.slice(0, 5) }}</span>
          </div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    schedule: {
      type: Object,
      required: true
    }
  }
}
</script>
